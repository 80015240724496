import React, { Link } from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Container, Button } from 'Common'
import dev from 'Static/illustrations/alexfid.jpg'
import { Wrapper, MusicWrapper, Details, Thumbnail } from './styles'

export const Music = () => (
  <Wrapper id="music">
    <MusicWrapper as={Container}>
      <Thumbnail>
        <img
          src={dev}
          alt="I’m Alex and I’m a coder, musician and dance caller!"
        />
      </Thumbnail>
      <Details>
        <h1>Music and Dance</h1>
        <p>
          I sing, play guitar, banjo, and fiddle mostly in the American old-time
          style. I call traditional southern square dances for adults and
          children at public and private events in the greater New York area.{' '}
          <br />
          <br />I play with the 5 Mile String Band,{' '}
          <a href="https://5milestringband.bandcamp.com" target="_blank">
            which you can hear here.
          </a>{' '}
          I also compose and write songs, some of which{' '}
          <a href="https://alexkramer.bandcamp.com/">you can hear here.</a> For
          lessons or performance inquiries, use the contact form below.
        </p>
        <Button as={AnchorLink} href="#contact">
          Contact me
        </Button>
      </Details>
    </MusicWrapper>
  </Wrapper>
)
