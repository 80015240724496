import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Container, Button } from 'Common'
import dev from 'Static/illustrations/dev.svg'
import { Wrapper, SkillsWrapper, Details, Thumbnail } from './styles'

export const Skills = () => (
  <Wrapper id="about">
    <SkillsWrapper as={Container}>
      <Thumbnail>
        <img
          src={dev}
          alt="I’m Alex and I’m a coder, musician and dance caller!"
        />
      </Thumbnail>
      <Details>
        <h1>About me</h1>
        <p>
          I'm a software engineer based in Brooklyn, NY. I'm also a bicycle
          adventurer, a folk musician, a square dance caller, a teacher, a chef,
          a farmer, and a dancer. <br />
          <br />
          For work, I write fullstack web, mobile and native apps. I mostly
          write in Node.js in the back end, and use React, Redux, and React
          Native in the front end. I use both PostgreSQL and NoSQL databases. I
          love creative projects involving art and technology, and especially
          love getting involved with grassroots activism. If you're curious
          about any of that, or anything else, feel free to reach out.
        </p>
        <Button as={AnchorLink} href="#contact">
          Contact me
        </Button>
      </Details>
    </SkillsWrapper>
  </Wrapper>
)
