import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Header } from 'Theme'
import { Container, Button } from 'Common'
import headshot from 'Static/illustrations/AKHeadshot.jpg'
import { Wrapper, IntroWrapper, Details, Thumbnail } from './styles'
import Typist from 'react-typist'

export const Intro = () => (
  <Wrapper>
    <Header />
    <IntroWrapper as={Container}>
      <Details>
        <Typist avgTypingDelay={30} cursor={{ show: false }}>
          <h1>Hi There!</h1>
          <h4>
            I’m Alex and I’m a full stack software engineer and web developer!
          </h4>
          <h5>
            I also play music, call square dances, and organize folk music
            events in NYC.
          </h5>
        </Typist>
        <Button as={AnchorLink} href="#contact">
          Hire Me!
        </Button>
      </Details>
      <Thumbnail>
        <img
          src={headshot}
          alt="I’m Alex and I’m a Fullstack, Front-end, & Backend engineer!  I also play fiddle and banjo, call square dances and organize folk music events in NYC."
        />
      </Thumbnail>
    </IntroWrapper>
  </Wrapper>
)
