import React from 'react'
import { Container, Card } from 'Common'
import { Wrapper, Grid, Item, Content, Stats } from './styles'

export const Projects = () => (
  <Wrapper as={Container} id="projects">
    <h2>Projects</h2>
    <Grid>
      {projs.map((node, index) => (
        <Item
          key={index}
          as="a"
          href={node.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Card>
            <Content>
              <h4>{node.name}</h4>
              <p>{node.description}</p>
            </Content>
          </Card>
        </Item>
      ))}
    </Grid>
  </Wrapper>
)

const projs = [
  {
    name: 'Soundshower',
    description:
      'Commissioned by Second State Live to create a mobile app for an art exhibit at a musuem in Chicago.  App was the control mechanism for musuem visitors to interact with a multimedia sculpture.  Written in React Native using Expo CLI.',
  },
  {
    name: 'Lingua',
    description: `Lingua is an immersive language learning platform built for
  mobile and Chrome extension. While using Chrome, users select
  words to learn in their target language. Those words are then
  sent to their account in the mobile app as a flashcard, which,
  once studied and marked as learned, is then translated by the
  Lingua Chrome extension on each webpage the user visits,
  creating a highly immersive learning environment. Lingua was
  built using Firebase, React Native, jQuery, and the Yandex
  translation API.`,
    url: `https://github.com/1810-lingua`,
  },
  {
    name: 'Placemkr',
    link: `https://github.com/alexkramerbanjo/placemkr`,
    description: `Placemkr is a geolocation based art-sharing mobile app. Users
    move through the real world and can access location-specific art
    as they change location. Users generate art in text, image and
    sound in the app, those artworks are then accessible only to
    other users in that same physical location. This project gets
    its name from the concept of placemaking - an urban planning
    concept that utilizes a local community's inspiration and
    potential to promote health, happiness and wellbeing. Placemkr
    was built in JavaScript using React Native, Expo and Firebase.`,
    url: `https://github.com/alexkramerbanjo/placemkr`,
  },
  {
    name: 'Jokeshop',
    description: `Jokeshop is a simulation e-commerce site that sells jokes. Users
      can shop for jokes, adding, deleting and editing the quantityt
      of items in their shopping carts. Users can create accounts to
      shop or shop anonymously. Jokeshop was built using Node.js and
      Express.js as a server, PostgreSQL as a database, and React,
      Redux, and Material UI on the front-end.`,
    url: `https://github.com/GraceShopper5/jokeshop`,
  },
]
