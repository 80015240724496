import React from 'react'
import { Layout, SEO } from 'Common'
import { Intro, Skills, Contact, Projects, Music } from 'Components/landing'

const IndexPage = () => (
  <Layout>
    <SEO />
    <Intro />
    <Projects />
    <Skills />
    <Music />
    <Contact />
  </Layout>
)

export default IndexPage
